<template>
  <nav class="navbar profile-navbar navbar-expand-lg px-0">
    <b-modal id="poledModal-mob" hide-footer hide-header size="lg">
      <div class="pole-modal-content" id="dialog">
        <button @click="$bvModal.hide('poledModal-mob')" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="pole-modal-title">{{ $t('profile.informed-modal.title') }}</div>
        <div class="" v-html="$t('poled-modal-text')">
        </div>
        <h1 class="title-polemodal">
          {{ $t('footer.terms-of-use') }}
        </h1>
        <div v-html=" $t('pol-term-of-use') ">

        </div>
        <el-checkbox class="confirm-pole-modal" style="  white-space: unset;" v-model="informedCheckbox">{{
            $t('pol-term-of-use-checkbox')
          }}
        </el-checkbox>
      </div>
    </b-modal>
    <b-modal id="poledModal-2-ru-mob" hide-footer hide-header size="lg">
      <div class="pole-modal-content" id="dialog">
        <button @click="$bvModal.hide('poledModal-2-ru-mob')" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <form style="margin-top: 2rem" ref="formElement">
          <div class="polemodalQuestions">Насколько сложной была для тебя олимпиада «Мың бала»?</div>
          <el-radio-group v-model="Questions.level">
            <el-radio label="Очень сложно">{{ $t('pole-modal.firstAnswers.answer-1') }}</el-radio>
            <el-radio label="Сложно">{{ $t('pole-modal.firstAnswers.answer-2') }}</el-radio>
            <el-radio label="Не знаю">{{ $t('pole-modal.firstAnswers.answer-3') }}</el-radio>
            <el-radio label="Легко">{{ $t('pole-modal.firstAnswers.answer-4') }}</el-radio>
            <el-radio label="Очень легко">{{ $t('pole-modal.firstAnswers.answer-5') }}</el-radio>
          </el-radio-group>
          <div class="polemodalQuestions"> Представь, что ты стал/стала победителем олимпиады Мын бала? Какой приз ты
            бы хотел/хотела получить за победу в олимпиаде?
          </div>
          <el-radio-group v-model="Questions.win">
            <el-radio label="Зачисление на учебу в лицей, гимназию, Дарын">{{
                $t('pole-modal.secondAnswers.answer-1')
              }}
            </el-radio>
            <el-radio label="Переехать в областной центр, столицу">{{
                $t('pole-modal.secondAnswers.answer-2')
              }}
            </el-radio>
            <el-radio label="Получить подарок">{{ $t('pole-modal.secondAnswers.answer-3') }}</el-radio>
            <el-radio label="Получить дополнительное обучение по предметам (репетиторы, онлайн-уроки)">
              {{ $t('pole-modal.secondAnswers.answer-4') }}
            </el-radio>
            <el-radio label="Получить доступ к интересным курсам (майнкрафт, мобиллография и т.п.)">
              {{ $t('pole-modal.secondAnswers.answer-5') }}
            </el-radio>
            <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textWin"
                                                                                    class="pole-modal-input"></input>
            </el-radio>
          </el-radio-group>
          <div class="polemodalQuestions">Как часто ты заходишь в интернет?</div>
          <el-radio-group v-model="Questions.ethernet">
            <el-radio label="У меня постоянный доступ в интернет">У меня постоянный доступ в интернет</el-radio>
            <el-radio label="Каждый день">Каждый день</el-radio>
            <el-radio label="3-4 раза в неделю">3-4 раза в неделю</el-radio>
            <el-radio label="1-2 раза в неделю">1-2 раза в неделю</el-radio>
            <el-radio label="Редко">Редко</el-radio>
          </el-radio-group>

          <div class="polemodalQuestions">С какого устройства ты обычно заходишь в интернет?</div>
          <el-radio-group v-model="Questions.deviceType">
            <el-radio label="У меня свой телефон">У меня свой телефон</el-radio>
            <el-radio label="Телефон родителей или родных">Телефон родителей или родных</el-radio>
            <el-radio label="Домашний компьютер или ноутбук">Домашний компьютер или ноутбук</el-radio>
            <el-radio label="Планшет">Планшет</el-radio>
            <el-radio label="Компьютер в школе">Компьютер в школе</el-radio>
            <el-radio label="Не знаю">Не знаю</el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.internetActivity.length>3}" class="polemodalQuestions">Что обычно
            ты делаешь в интернете? (можно выбрать 3 варианта)
          </div>
          <el-checkbox-group v-model="Questions.internetActivity">
            <el-checkbox label="Читаю новости"></el-checkbox>
            <el-checkbox label="Ищу полезную информацию (интересы, хобби, волнующие вопросы)"></el-checkbox>
            <el-checkbox label="Общаюсь в социальных сетях"></el-checkbox>
            <el-checkbox label="Смотрю блогеров"></el-checkbox>
            <el-checkbox label="Играю в онлайн-игры"></el-checkbox>
            <el-checkbox label="Учусь"></el-checkbox>
            <el-checkbox label="Слушаю или скачиваю музыку"></el-checkbox>
            <el-checkbox label="Слушаю подкасты и аудиокниги"></el-checkbox>
            <el-checkbox label="Веду интернет-дневник (тик-ток, инстаграм и т.п.)"></el-checkbox>
            <el-checkbox label="Смотрю фильмы"></el-checkbox>
            <el-checkbox label="Делаю покупки в интернете"></el-checkbox>
            <el-checkbox label="Слушаю и смотрю контент на религиозные темы"></el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal"
                v-if="Questions.internetActivity.length>3">Максимальное число вариантов: 3</span>
          <div class="polemodalQuestions">Есть ли у вас в доме компьютер или ноутбук?</div>
          <el-radio-group v-model="Questions.haveComputer">
            <el-radio label="Да">Да</el-radio>
            <el-radio label="Нет">Нет</el-radio>
          </el-radio-group>
          <div class="polemodalQuestions">Как далеко находится школа от дома?</div>
          <el-radio-group v-model="Questions.schoolDistance">
            <el-radio label="Близко (до 30 минут на дорогу)">Близко (до 30 минут на дорогу)</el-radio>
            <el-radio label="Далеко (около 1 часа на дорогу)">Далеко (около 1 часа на дорогу)</el-radio>
            <el-radio label="Очень далеко (более 1 часа на дорогу)">Очень далеко (более 1 часа на дорогу)</el-radio>
            <el-radio label="В другом населенном пункте (соседняя деревня/ я живу в пригороде, а школа в центре)">В
              другом населенном пункте (соседняя деревня/ я живу в пригороде, а школа в центре)
            </el-radio>
            <el-radio label="Живу в школе (интернат)">Живу в школе (интернат)</el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.schoolProblems.length>3}" class="polemodalQuestions">Какие
            проблемы ты видишь в учебе? (можно выбрать 3 варианта)
          </div>
          <el-checkbox-group v-model="Questions.schoolProblems">
            <el-checkbox label="Дорога до школы"></el-checkbox>
            <el-checkbox label="Проблемы с одеждой"></el-checkbox>
            <el-checkbox label="В школе нет хороших компьютеров, мебели, спортивных снарядов"></el-checkbox>
            <el-checkbox label="Я не понимаю некоторые тему уроков"></el-checkbox>
            <el-checkbox label="Мне неинтересно сидеть на уроках"></el-checkbox>
            <el-checkbox label="Нет денег на репетиторов или платные кружки"></el-checkbox>
            <el-checkbox label="Нет времени на усердную учебу"></el-checkbox>
            <el-checkbox label="Некоторые темы предметов слишком сложные"></el-checkbox>
            <el-checkbox label="У меня нет друзей"></el-checkbox>
            <el-checkbox label="Некому помочь мне с учебой"></el-checkbox>
            <el-checkbox label="Я не хочу учиться"></el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal"
                v-if="Questions.schoolProblems.length>3">Максимальное число вариантов: 3</span>
          <div class="polemodalQuestions">Кто помогает тебе с уроками, если нужна помощь?</div>
          <el-radio-group v-model="Questions.schoolHelper">
            <el-radio label="Мама">Мама</el-radio>
            <el-radio label="Папа или мама, у кого есть время">Папа или мама, у кого есть время</el-radio>
            <el-radio label="Другие родственники (бабушка, старший брат и т.п)">Другие родственники (бабушка, старший
              брат и т.п)
            </el-radio>
            <el-radio label="Я не понимаю некоторые тему уроков">Я не понимаю некоторые тему уроков</el-radio>
            <el-radio label="Репетитор">Репетитор</el-radio>
            <el-radio label="Учитель в дополнительное время">Учитель в дополнительное время</el-radio>
            <el-radio label="Продленка в школе">Продленка в школе</el-radio>
            <el-radio label="Я делаю уроки сам/сама">Я делаю уроки сам/сама</el-radio>
          </el-radio-group>


          <div class="polemodalQuestions">Как ты думаешь, твой класс дружный?</div>
          <el-radio-group v-model="Questions.friendlyClass">
            <el-radio label="Да, мы все дружим">Да, мы все дружим</el-radio>
            <el-radio label="В основном да, но есть ребята с которыми не хотят дружить">В основном да, но есть ребята
              с которыми не хотят дружить
            </el-radio>
            <el-radio label="В классе дружим маленькими группами">В классе дружим маленькими группами</el-radio>
            <el-radio label="У меня есть несколько друзей, но класс не дружный">У меня есть несколько друзей, но класс
              не дружный
            </el-radio>
            <el-radio label="У нас каждый сам по себе">У нас каждый сам по себе</el-radio>
          </el-radio-group>

          <div class="polemodalQuestions">Учителя бывают с тобой строги?</div>
          <el-radio-group v-model="Questions.friendlyTeacher">
            <el-radio label="Да, иногда слишком">Да, иногда слишком</el-radio>
            <el-radio label="Да, в основном это справедливо">Да, в основном это справедливо</el-radio>
            <el-radio label="Со мной нет, но с другими учениками бывает">Со мной нет, но с другими учениками бывает
            </el-radio>
            <el-radio label="Нет, учителя не ругают">Нет, учителя не ругают</el-radio>
            <el-radio label="Не знаю">Не знаю</el-radio>
          </el-radio-group>


          <div class="polemodalQuestions">Посещаешь кружки или секции?</div>
          <el-radio-group v-model="Questions.sections">
            <el-radio label="Да (посещаю офлайн)">Да (посещаю офлайн)</el-radio>
            <el-radio label="Да (посещаю онлайн)">Да (посещаю онлайн)</el-radio>
            <el-radio label="Да (онлайн и офлайн)">Да (онлайн и офлайн)</el-radio>
            <el-radio label="Я хочу, но нет возможностей">Я хочу, но нет возможностей</el-radio>
            <el-radio label="Я не знаю какие есть кружки и секции">Я не знаю какие есть кружки и секции</el-radio>
            <el-radio label="Мне это неинтересно">Мне это неинтересно</el-radio>
            <el-radio label="Нет">Нет</el-radio>
          </el-radio-group>

          <div class="polemodalQuestions">Хочешь иметь доступ к кружкам онлайн?</div>
          <el-radio-group v-model="Questions.onlineSections">
            <el-radio label="Да">Да</el-radio>
            <el-radio label="Нет">Нет</el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.it.length>3}" class="polemodalQuestions">Выбери понравившийся
            кружок-онлайн (ИТ-технологии)? (можно выбрать 3
            варианта)
          </div>
          <el-checkbox-group v-model="Questions.it">
            <el-checkbox label="Блочное программирование (Kodu, Minecraft, Roblox)">Блочное программирование (Kodu,
              Minecraft, Roblox)
            </el-checkbox>
            <el-checkbox label="Разработка сайтов">Разработка сайтов
            </el-checkbox>
            <el-checkbox label="Фотография">Фотография
            </el-checkbox>
            <el-checkbox label="Компьютерная графика (Photoshop, Illustrator)">Компьютерная графика (Photoshop,
              Illustrator)
            </el-checkbox>
            <el-checkbox label="Создание мультфильмов">Создание мультфильмов
            </el-checkbox>
            <el-checkbox label="Криптовалюта (основы технологии блокчейн)">Криптовалюта (основы технологии блокчейн)
            </el-checkbox>
            <el-checkbox label="Ничего не нравится">Ничего не нравится
            </el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textIt"
                                                                                       class="pole-modal-input"></input>
            </el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.it.length>3">Максимальное число вариантов: 3</span>

          <div :class="{'error-pole-modal':Questions.creations.length>3}" class="polemodalQuestions">Выбери
            понравившийся кружок-онлайн (Творчество)? (можно выбрать 3 варианта)
            *
          </div>
          <el-checkbox-group v-model="Questions.creations">
            <el-checkbox label="Домбра"></el-checkbox>
            <el-checkbox label="Гитара"></el-checkbox>
            <el-checkbox label="Дизайн"></el-checkbox>
            <el-checkbox label="Фортепиано"></el-checkbox>
            <el-checkbox label="Асык"></el-checkbox>
            <el-checkbox label="Тогыз-Кумалак"></el-checkbox>
            <el-checkbox label="Ничего не нравится"></el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                v-model="Questions.textCreations"
                class="pole-modal-input"></input>
            </el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.creations.length>3">Максимальное число вариантов: 3</span>

          <div :class="{'error-pole-modal':Questions.sport.length>3}" class="polemodalQuestions">Выбери понравившийся
            кружок-онлайн (Спорт)? (можно выбрать 3 варианта)
          </div>
          <el-checkbox-group v-model="Questions.sport">
            <el-checkbox label="Workout">Workout</el-checkbox>
            <el-checkbox label="Футбольный фристайл">Футбольный фристайл</el-checkbox>
            <el-checkbox label="Street dance">Street dance</el-checkbox>
            <el-checkbox label="Шахматы">Шахматы</el-checkbox>
            <el-checkbox label="Йога">Йога</el-checkbox>
            <el-checkbox label="Ничего не нравится">Ничего не нравится</el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textSport"
                                                                                       class="pole-modal-input"></input>
            </el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.sport.length>3">Максимальное число вариантов: 3</span>
          <div class="polemodalQuestions">Как обычно ты проводишь каникулы?
          </div>
          <el-radio-group v-model="Questions.summerTime">
            <el-radio label="Дома, помогаю по хозяйству">Дома, помогаю по хозяйству
            </el-radio>
            <el-radio label="Дома, занимаюсь любимыми развлечениями (играю, смотрю фильмы и т.п.)">Дома, занимаюсь
              любимыми развлечениями (играю, смотрю фильмы и т.п.)
            </el-radio>
            <el-radio label="Хотя бы раз в год ездим семьей отдыхать">Хотя бы раз в год ездим семьей отдыхать
            </el-radio>
            <el-radio label="Еду к родственникам в другой город или деревню">Еду к родственникам в другой город или
              деревню
            </el-radio>
            <el-radio label="Дополнительно учусь, готовлюсь к олимпиадам, занимаюсь с репетитором и т.п.">Дополнительно
              учусь, готовлюсь к олимпиадам, занимаюсь с репетитором и т.п
            </el-radio>
            <el-radio label="Занимаюсь в спортивном или творческом кружке более усиленно">Занимаюсь в спортивном или
              творческом кружке более усиленно
            </el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.profession.length>3}" class="polemodalQuestions">Кем ты хочешь
            стать в будущем? (можно выбрать 3 варианта)
          </div>
          <el-checkbox-group v-model="Questions.profession">
            <el-checkbox label="Бизнесменом">Бизнесменом</el-checkbox>
            <el-checkbox label="Киберспортсменом, геймером">Киберспортсменом, геймером</el-checkbox>
            <el-checkbox label="Телеведущим, журналистом">Телеведущим, журналистом</el-checkbox>
            <el-checkbox label="Полицейским, военным">Полицейским, военным</el-checkbox>
            <el-checkbox label="Художником">Художником</el-checkbox>
            <el-checkbox label="Врачом">Врачом</el-checkbox>
            <el-checkbox label="Музыкантом, танцором">Музыкантом, танцором</el-checkbox>
            <el-checkbox label="Юристом">Юристом</el-checkbox>
            <el-checkbox label="Не знаю">Не знаю</el-checkbox>
            <el-checkbox label="Спортсменом">Спортсменом</el-checkbox>
            <el-checkbox label="Президентом, депутатом, министром">Президентом, депутатом, министром</el-checkbox>
            <el-checkbox label="Блогером, тиктокером, ютубером">Блогером, тиктокером, ютубером</el-checkbox>
            <el-checkbox label="Финансистом, работать в банке">Финансистом, работать в банке</el-checkbox>
            <el-checkbox label="Космонавтом">Космонавтом</el-checkbox>
            <el-checkbox label="IT-специалистом">IT-специалистом</el-checkbox>
            <el-checkbox label="Актером, певцом">Актером, певцом</el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                v-model="Questions.textProfession" class="pole-modal-input"></input></el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.profession.length>3">Максимальное число вариантов: 3</span>

          <div class="polemodalQuestions">Хотел/хотела бы ты поехать в другую страну?
          </div>
          <el-radio-group v-model="Questions.country">
            <el-radio label="Да, я планирую переехать жить в другую страну, когда вырасту">Да, я планирую переехать
              жить в другую страну, когда вырасту
            </el-radio>
            <el-radio label="Я хочу получить образование в другой стране">Я хочу получить образование в другой стране
            </el-radio>
            <el-radio label="Я хочу работать в другой стране">Я хочу работать в другой стране
            </el-radio>
            <el-radio label="Я хочу много путешествовать, но жить в Казахстане">Я хочу много путешествовать, но жить в
              Казахстане
            </el-radio>
            <el-radio label="Возможно, только на отдых">Возможно, только на отдых
            </el-radio>
            <el-radio label="Не знаю">Не знаю
            </el-radio>
            <el-radio label="Нет">Нет
            </el-radio>
          </el-radio-group>


          <div class="button-polemodal-wrapper">
            <el-button @click="sendForm()" type="primary">{{ $t('pole-modal.send') }}</el-button>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="poledModal-2-kz-mob" hide-footer hide-header size="lg">
      <div class="pole-modal-content" id="dialog">
        <button @click="$bvModal.hide('poledModal-2-ru-mob')" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <form style="margin-top: 2rem" ref="formElement">
          <div class="polemodalQuestions">«Мың бала» олимпиадасы саған қаншалықты қиын болды? *</div>
          <el-radio-group v-model="Questions.level">
            <el-radio label="Өте қиын">Өте қиын</el-radio>
            <el-radio label="Қиын">Қиын</el-radio>
            <el-radio label="Білмеймін">Білмеймін</el-radio>
            <el-radio label="Оңай">Оңай</el-radio>
            <el-radio label="Өте оңай">Өте оңай</el-radio>
          </el-radio-group>
          <div class="polemodalQuestions"> Сен Мың бала олимпиадасының жеңімпазы атандың делік. Олимпиададағы жеңісің
            үшін қандай сыйлық алғың келер еді?
          </div>
          <el-radio-group v-model="Questions.win">
            <el-radio label="Лицейге, гимназияға, Дарынға оқуға қабылдану">Лицейге, гимназияға, Дарынға оқуға
              қабылдану
            </el-radio>
            <el-radio label="Облыс орталығына, астанаға көшу">Облыс орталығына, астанаға көшу</el-radio>
            <el-radio label="Сыйлық алу">Сыйлық алу</el-radio>
            <el-radio label="Пәндер бойынша қосымша білім алу (репетиторлар, онлайн-сабақтар)">Пәндер бойынша қосымша
              білім алу (репетиторлар, онлайн-сабақтар)
            </el-radio>
            <el-radio label="Қызықты курстарға жазылу (майнкрафт, мобилография және т.б.)">Қызықты курстарға жазылу
              (майнкрафт, мобилография және т.б.)
            </el-radio>
            <el-radio label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textWin"
                                                                                    class="pole-modal-input"></input>
            </el-radio>
          </el-radio-group>
          <div class="polemodalQuestions">Интернетке қаншалықты жиі кіресіз?</div>
          <el-radio-group v-model="Questions.ethernet">
            <el-radio label="Менің интернетке тұрақты түрде кіруге мүмкіндігім бар">Менің интернетке тұрақты түрде
              кіруге мүмкіндігім бар
            </el-radio>
            <el-radio label="Күнде">Күнде</el-radio>
            <el-radio label="Аптасына 3-4 рет">Аптасына 3-4 рет</el-radio>
            <el-radio label="Аптасына 1-2 рет">Аптасына 1-2 рет</el-radio>
            <el-radio label="Сирек">Сирек</el-radio>
          </el-radio-group>

          <div class="polemodalQuestions">Сіз әдетте интернетке қай құрылғымен кіресіз?</div>
          <el-radio-group v-model="Questions.deviceType">
            <el-radio label="Өзімнің телефоным бар">Өзімнің телефоным бар</el-radio>
            <el-radio label="Ата-анасының немесе туыстарының телефоны">Ата-анасының немесе туыстарының телефоны
            </el-radio>
            <el-radio label="Үй компьютері немесе ноутбук">Үй компьютері немесе ноутбук</el-radio>
            <el-radio label="Планшет">Планшет</el-radio>
            <el-radio label="Мектептегі компьютер">Мектептегі компьютер</el-radio>
            <el-radio label="Білмеймін">Білмеймін</el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.internetActivity.length>3}" class="polemodalQuestions">
            Интернетте әдетте не істейсіз? (3 нұсқаны таңдауға болады)
          </div>
          <el-checkbox-group v-model="Questions.internetActivity">
            <el-checkbox label="Блогерлерді қараймын"></el-checkbox>
            <el-checkbox label="Мен интернет-күнделік жүргіземін (тик-ток, іnstagram және т. б.)"></el-checkbox>
            <el-checkbox label="Діни тақырыптарды тыңдаймын және қараймын"></el-checkbox>
            <el-checkbox label="Білімімді жетілдіремін"></el-checkbox>
            <el-checkbox label="Подкасттар мен аудиокітаптарды тыңдаймын"></el-checkbox>
            <el-checkbox label="Жаңалықтар оқимын"></el-checkbox>
            <el-checkbox label="Интернеттен керегімді сатып аламын"></el-checkbox>
            <el-checkbox label="Әлеуметтік желілерде сөйлесемін"></el-checkbox>
            <el-checkbox
                label="Пайдалы ақпарат іздеймін (қызығушылықтар, хобби, қызықтыратын сұрақтар)"></el-checkbox>
            <el-checkbox label="Фильмдер көремін"></el-checkbox>
            <el-checkbox label="Музыка тыңдаймын немесе жүктеймін"></el-checkbox>
            <el-checkbox label="Мен онлайн ойындар ойнаймын"></el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal"
                v-if="Questions.internetActivity.length>3">3 нұсқаны таңдауға болады</span>

          <div class="polemodalQuestions">Үйде компьютер немесе ноутбук бар ма?</div>
          <el-radio-group v-model="Questions.haveComputer">
            <el-radio label="Иә">Иә</el-radio>
            <el-radio label="Жоқ">Жоқ</el-radio>
          </el-radio-group>
          <div class="polemodalQuestions">Мектеп үйден қаншалықты алыс?</div>
          <el-radio-group v-model="Questions.schoolDistance">
            <el-radio label="Жақын (жолға 30 минутқа дейін кетеді)">Жақын (жолға 30 минутқа дейін кетеді)</el-radio>
            <el-radio label="Алыс (жолға шамамен 1 сағат керек)">Алыс (жолға шамамен 1 сағат керек)</el-radio>
            <el-radio label="Өте алыс (жолға 1 сағаттан астам уақыт керек)">Өте алыс (жолға 1 сағаттан астам уақыт
              керек)
            </el-radio>
            <el-radio label="Басқа елді мекенде (көрші ауыл/қала маңында тұрамын, ал мектеп орталықта)">Басқа елді
              мекенде (көрші ауыл/қала маңында тұрамын, ал мектеп орталықта)
            </el-radio>
            <el-radio label="Мен мектепте тұрамын (интернат)">Мен мектепте тұрамын (интернат)</el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.schoolProblems.length>3}" class="polemodalQuestions">Оқуда қандай
            проблемаларды атап өтер едің? (3 нұсқаны таңдауға болады)
          </div>
          <el-checkbox-group v-model="Questions.schoolProblems">
            <el-checkbox label="Мен кейбір сабақ тақырыбын түсінбеймін"></el-checkbox>
            <el-checkbox label="Кейбір тақырыптар өте күрделі"></el-checkbox>
            <el-checkbox label="Мектепке дейінгі жол"></el-checkbox>
            <el-checkbox label="Менің достарым жоқ"></el-checkbox>
            <el-checkbox label="Мен оқығым келмейді"></el-checkbox>
            <el-checkbox label="Киім проблемасы"></el-checkbox>
            <el-checkbox label="Репетиторлар мен ақылы үйірмелерге ақша жоқ"></el-checkbox>
            <el-checkbox label="Терең білім алуға уақыт жоқ"></el-checkbox>
            <el-checkbox label="Мектепте жақсы компьютерлер, жиһаздар, спорттық жабдықтар жоқ"></el-checkbox>
            <el-checkbox label="Маған оқуға көмектесетін ешкім жоқ"></el-checkbox>
            <el-checkbox label="Маған сабақта отыру қызық емес"></el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal"
                v-if="Questions.schoolProblems.length>3">3 нұсқаны таңдауға болады</span>
          <div class="polemodalQuestions">Егер көмек қажет болса, сабақ оқуға кім көмектеседі?</div>
          <el-radio-group v-model="Questions.schoolHelper">
            <el-radio label="Анам">Анам</el-radio>
            <el-radio label="Уақыты болса әкем немесе анам">Уақыты болса әкем немесе анам</el-radio>
            <el-radio label="Әжем немесе атам">Әжем немесе атам
            </el-radio>
            <el-radio label="Ағам немесе апам">Ағам немесе апам</el-radio>
            <el-radio label="Репетитор, мұғалім">Репетитор, мұғалім</el-radio>
            <el-radio label="Мен сабақты өзім істеймін">Мен сабақты өзім істеймін</el-radio>

          </el-radio-group>


          <div class="polemodalQuestions">Сенің сыныбың тату деп ойлайсың ба? *</div>
          <el-radio-group v-model="Questions.friendlyClass">
            <el-radio label="Ия, біз бәріміз доспыз">Ия, біз бәріміз доспыз</el-radio>
            <el-radio label="Негізінен иә, бірақ достасқысы келмейтін сыныптастар бар">Негізінен иә, бірақ достасқысы
              келмейтін сыныптастар бар
            </el-radio>
            <el-radio label="Сыныпта кішкентай топтармен дос боламыз">Сыныпта кішкентай топтармен дос боламыз
            </el-radio>
            <el-radio label="Менің бірнеше досым бар, бірақ сынып тату емес">Менің бірнеше досым бар, бірақ сынып тату
              емес
            </el-radio>
            <el-radio label="Бізде әркім өзімен өзі">Бізде әркім өзімен өзі</el-radio>
          </el-radio-group>

          <div class="polemodalQuestions">Мұғалімдер саған қаталдық таныта ма?</div>
          <el-radio-group v-model="Questions.friendlyTeacher">
            <el-radio label="Иә, кейде тым көп мөлшерде">Иә, кейде тым көп мөлшерде</el-radio>
            <el-radio label="Иә, бұл негізінен әділ болады">Иә, бұл негізінен әділ болады</el-radio>
            <el-radio label="Маған қатал емес, бірақ басқа оқушыларға қатал">Маған қатал емес, бірақ басқа оқушыларға
              қатал
            </el-radio>
            <el-radio label="Жоқ, мұғалімдер ұрыспайды">Жоқ, мұғалімдер ұрыспайды</el-radio>
            <el-radio label="Білмеймін">Білмеймін</el-radio>
          </el-radio-group>


          <div class="polemodalQuestions">Үйірмелерге немесе секцияларға барасыз ба?</div>
          <el-radio-group v-model="Questions.sections">
            <el-radio label="Иә (офлайн қатысамын)">Иә (офлайн қатысамын)</el-radio>
            <el-radio label="Иә (онлайн қатысамын)">Иә (онлайн қатысамын)</el-radio>
            <el-radio label="Иә (онлайн әрі офлайн)">Иә (онлайн әрі офлайн)</el-radio>
            <el-radio label="Мен қалаймын, бірақ мүмкіндік жоқ">Мен қалаймын, бірақ мүмкіндік жоқ</el-radio>
            <el-radio label="Мен қандай үйірмелер мен секциялардың  бар екенін білмеймін">Мен қандай үйірмелер мен
              секциялардың бар екенін білмеймін
            </el-radio>
            <el-radio label="Бұл маған қызық емес">Бұл маған қызық емес</el-radio>
            <el-radio label="Жоқ">Жоқ</el-radio>
          </el-radio-group>

          <div class="polemodalQuestions">Онлайн үйірмелерге қол жеткізгіңіз келе ме?</div>
          <el-radio-group v-model="Questions.onlineSections">
            <el-radio label="Иә">Иә</el-radio>
            <el-radio label="Жоқ">Жоқ</el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.it.length>3}" class="polemodalQuestions">Өзіңізге ұнайтын онлайн
            үйірмені таңдаңыз (IT-технологиялар)? (3 нұсқаны таңдауға болады)
          </div>
          <el-checkbox-group v-model="Questions.it">
            <el-checkbox label="Блоктық бағдарламалау (Kodu, Minecraft, Roblox)">Блоктық бағдарламалау (Kodu,
              Minecraft, Roblox)
            </el-checkbox>
            <el-checkbox label="Сайттарды әзірлеу">Сайттарды әзірлеу
            </el-checkbox>
            <el-checkbox label="Фотосурет">Фотосурет
            </el-checkbox>
            <el-checkbox label="Компьютерлік графика (Photoshop, Illustrator)">Компьютерлік графика (Photoshop,
              Illustrator)
            </el-checkbox>
            <el-checkbox label="Мультфильмдер жасау">Мультфильмдер жасау
            </el-checkbox>
            <el-checkbox label="Криптовалюта (блокчейн технологиясының негіздері)">Криптовалюта (блокчейн
              технологиясының негіздері)
            </el-checkbox>
            <el-checkbox label="Ештеңе ұнамайды">Ештеңе ұнамайды
            </el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textIt"
                                                                                       class="pole-modal-input"></input>
            </el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.it.length>3">3 нұсқаны таңдауға болады</span>

          <div :class="{'error-pole-modal':Questions.creations.length>3}" class="polemodalQuestions">Өзіңізге ұнайтын
            онлайн үйірмені таңдаңыз (Шығармашылық)? (3 нұсқаны таңдауға болады)
            *
          </div>
          <el-checkbox-group v-model="Questions.creations">
            <el-checkbox label="Домбыра"></el-checkbox>
            <el-checkbox label="Гитара"></el-checkbox>
            <el-checkbox label="Дизайн"></el-checkbox>
            <el-checkbox label="Фортепиано"></el-checkbox>
            <el-checkbox label="Асық"></el-checkbox>
            <el-checkbox label="Тоғызқұмалақ"></el-checkbox>
            <el-checkbox label="Ештеңе ұнамайды"></el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                v-model="Questions.textCreations"
                class="pole-modal-input"></input>
            </el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.creations.length>3">3 нұсқаны таңдауға болады</span>

          <div :class="{'error-pole-modal':Questions.sport.length>3}" class="polemodalQuestions">Өзіңізге ұнайтын
            онлайн үйірмені таңдаңыз (Спорт)? (3 нұсқаны таңдауға болады)
            (3 нұсқаны таңдауға болады)
          </div>
          <el-checkbox-group v-model="Questions.sport">
            <el-checkbox label="Workout">Workout</el-checkbox>
            <el-checkbox label="Футбол фристайлы">Футбол фристайлы</el-checkbox>
            <el-checkbox label="Street dance">Street dance</el-checkbox>
            <el-checkbox label="Шахмат">Шахмат</el-checkbox>
            <el-checkbox label="Йога">Йога</el-checkbox>
            <el-checkbox label="Ештеңе ұнамайды">Ештеңе ұнамайды</el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input v-model="Questions.textSport"
                                                                                       class="pole-modal-input"></input>
            </el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.sport.length>3">3 нұсқаны таңдауға болады</span>
          <div class="polemodalQuestions">Әдетте демалысты қалай өткізесің?
          </div>
          <el-radio-group v-model="Questions.summerTime">
            <el-radio label="Үйде ата-анама үй шаруасында көмектесемін">Үйде ата-анама үй шаруасында көмектесемін
            </el-radio>
            <el-radio label="Үйде мен сүйікті ойын-сауықтармен  айналысамын (ойнаймын, фильмдер көремін және т. б.)">
              Үйде мен сүйікті ойын-сауықтармен айналысамын (ойнаймын, фильмдер көремін және т. б.)
            </el-radio>
            <el-radio label="Кем дегенде жылына бір рет отбасымызбен демалуға барамыз">Кем дегенде жылына бір рет
              отбасымызбен демалуға барамыз
            </el-radio>
            <el-radio label="Басқа қаладағы немесе ауылдағы туыстарыма қыдырамын">Басқа қаладағы немесе ауылдағы
              туыстарыма қыдырамын
            </el-radio>
            <el-radio label="Қосымша білім аламын, олимпиадаларға дайындаламын, репетитормен оқимын және т. б.">
            </el-radio>
            <el-radio label="Спорттық немесе шығармашылық үйірмедегі қатысуымды күшейтемін">Спорттық немесе
              шығармашылық үйірмедегі қатысуымды күшейтемін
            </el-radio>
          </el-radio-group>

          <div :class="{'error-pole-modal':Questions.profession.length>3}" class="polemodalQuestions">Болашақта кім
            болғың келеді? (3 нұсқаны таңдауға болады)
          </div>
          <el-checkbox-group v-model="Questions.profession">
            <el-checkbox label="Ғалым">Ғалым</el-checkbox>
            <el-checkbox label="Актер, әнші">Актер, әнші</el-checkbox>
            <el-checkbox label="Дәрігер">Дәрігер</el-checkbox>
            <el-checkbox label="Полиция, әскери адам">Полиция, әскери адам</el-checkbox>
            <el-checkbox label="Музыкант, биші">Музыкант, биші</el-checkbox>
            <el-checkbox label="Суретші">Суретші</el-checkbox>
            <el-checkbox label="Ғарышкер">Ғарышкер</el-checkbox>
            <el-checkbox label="Президент, депутат, министр">Президент, депутат, министр</el-checkbox>
            <el-checkbox label="Блогер, тиктокер, ютубер">Блогер, тиктокер, ютубер</el-checkbox>
            <el-checkbox label="Мұғалім">Мұғалім</el-checkbox>
            <el-checkbox label="Спортшы">Спортшы</el-checkbox>
            <el-checkbox label="Қаржыгер, банкте жұмыс істеу">Қаржыгер, банкте жұмыс істеу</el-checkbox>
            <el-checkbox label="Білмеймін">Білмеймін</el-checkbox>
            <el-checkbox label="Тележүргізуші, журналист">Тележүргізуші, журналист</el-checkbox>
            <el-checkbox label="Бизнесмен">Бизнесмен</el-checkbox>
            <el-checkbox label="IT маманы">IT маманы</el-checkbox>
            <el-checkbox label="Киберспортшы, геймер">Киберспортшы, геймер</el-checkbox>
            <el-checkbox label="Заңгер">Заңгер</el-checkbox>
            <el-checkbox label="__other_option__">{{ $t('pole-modal.other') }}: <input
                v-model="Questions.textProfession" class="pole-modal-input"></input></el-checkbox>
          </el-checkbox-group>
          <span class="error-pole-modal" v-if="Questions.profession.length>3">3 нұсқаны таңдауға болады</span>

          <div class="polemodalQuestions">Басқа елге барғың келе ме?
          </div>
          <el-radio-group v-model="Questions.country">
            <el-radio label="Иә, мен өскен кезде басқа елге көшуді жоспарлап отырмын">Иә, мен өскен кезде басқа елге
              көшуді жоспарлап отырмын
            </el-radio>
            <el-radio label="Мен басқа елде білім алғым келеді">Мен басқа елде білім алғым келеді
            </el-radio>
            <el-radio label="Мен басқа елде жұмыс істегім келеді">Мен басқа елде жұмыс істегім келеді
            </el-radio>
            <el-radio label="Мен көп саяхаттағым келеді, бірақ Қазақстанда өмір сүргім келеді">Мен көп саяхаттағым
              келеді, бірақ Қазақстанда өмір сүргім келеді
            </el-radio>
            <el-radio label="Мүмкін тек демалу үшін ғана">Мүмкін тек демалу үшін ғана
            </el-radio>
            <el-radio label="Білмеймін">Білмеймін
            </el-radio>
            <el-radio label="Жоқ">Жоқ
            </el-radio>
          </el-radio-group>


          <div class="button-polemodal-wrapper">
            <el-button @click="sendForm()" type="primary">{{ $t('pole-modal.send') }}</el-button>
          </div>
        </form>
      </div>
    </b-modal>
    <div class="w-100 px-2 profile-navbar-site">
      <!--      <div class="navbar-brand float-left">{{ title }}</div>-->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 dropdown d-none">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle special-view-button">
              <i class="special-view-icon"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-circle-check">Action 1</el-dropdown-item>
                <el-dropdown-item icon="el-icon-circle-check">Action 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="nav-item mx-0 dropdown">
            <el-dropdown @command="switchLocale" trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link">{{ $i18n.locale }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="kz">Казахский</el-dropdown-item>
                <el-dropdown-item command="ru">Русский</el-dropdown-item>
                <!--                <el-dropdown-item command="en">Английский</el-dropdown-item>-->
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="nav-item mx-0 dropdown d-none">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link"><i class="notification-icon"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>Notification 1</el-dropdown-item>
                <el-dropdown-item>Notification 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li class="nav-item exit-link">
            <a @click.prevent="logoutMethod" class="nav-link cursor-pointer">{{ $t('profile.logout') }}</a>
          </li>
          <li class="nav-item">
            <a href="/"><img src="/images/1000-BALA-logo.svg" width="44"></a>
            <!--              <router-link to="/" tag="a"><img src="images/profile/logo.svg" width="44"></router-link>-->
          </li>
        </ul>
      </div>
    </div>


    <div class="profile-navbar-mobile w-100">
      <div class="nav-logo">
        <a href="/"><img src="/images/navbar-new.svg" width="60"></a>
      </div>
      <div class="menu-block">
        <ul class="navbar-nav">
          <li class="nav-item mx-0 dropdown">
            <el-dropdown @command="switchLocale" trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link">{{ $i18n.locale }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="kz">Казахский</el-dropdown-item>
                <el-dropdown-item command="ru">Русский</el-dropdown-item>
                <!--                <el-dropdown-item command="en">Английский</el-dropdown-item>-->
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="menu-block d-none">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 dropdown">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle special-view-button">
              <i class="special-view-icon"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-circle-check">Action 1</el-dropdown-item>
                <el-dropdown-item icon="el-icon-circle-check">Action 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="menu-block d-none">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 dropdown">
            <el-dropdown trigger="click" class="nav-link dropdown-toggle change-locale-button">
              <span class="el-dropdown-link"><i class="notification-icon"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>Notification 1</el-dropdown-item>
                <el-dropdown-item>Notification 2</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="modal-menu-btn" data-toggle="modal" data-target="#modalMenu"
           onclick="document.getElementById('id01').style.display='block'">
        <img src="/images/collapse-icon.svg" width="40">
      </div>
    </div>

    <div id="id01" class="w3-modal">
      <div class="w3-modal-content">
        <div class="w3-container">
          <button onclick="document.getElementById('id01').style.display='none'" class="fa closeIcon btn-back-menu"
                  aria-hidden="true">
            <img src="/images/arrow_back-menu.png" alt="">
          </button>
          <div class="sidebar sideBarMobile profile-sidebar">
            <div class="profile">
              <div class="avatar" :style="'background-image: url(' + avatar + ');'"></div>
              <div class="username">{{ user.first_name }} {{ user.last_name }}</div>
              <div v-if="user.education" class="role text-center">
                {{ (user.education.status == 0) ? $t('sidebar.member') : '' }}
              </div>
            </div>
            <div class="profile-sidebar-menu">
              <a v-if="showPoleButton" @click="openModal()" class="polled-modal-button">{{
                  $t('pole-modal.survey')
                }}</a>

              <router-link tag="a" to="/cabinet" class="cabinet" :class="{'active': $route.path === '/cabinet'}">
                {{ $t('sidebar.cabinet') }}
              </router-link>
              <router-link tag="a" to="/profile" class="profile" :class="{'active': $route.path === '/profile'}">
                {{ $t('sidebar.profile') }}
              </router-link>
              <router-link tag="a" to="/testing" class="testing" :class="{'active': $route.path === '/testing'}">
                {{ $t('sidebar.testing') }}
              </router-link>
              <router-link tag="a" to="/trial-tests" class="testing" :class="{'active': $route.path === '/trial-tests'}">
                {{ $t('trial-tests') }}
              </router-link>
<!--              <router-link tag="a" to="/my_teachers" class="testing" :class="{'active': $route.path === '/my_teachers'}">-->
<!--                {{ $t('profile.my_teachers') }}-->
<!--              </router-link>-->
<!--              <router-link tag="a" to="/my-results" class="testing" :class="{'active': $route.path === '/results'}">-->
<!--                {{ $t('my_results_1')}}-->
<!--              </router-link>-->
<!--              <router-link tag="a" to="/results" class="testing" :class="{'active': $route.path === '/results'}">-->
<!--                {{ $t('results') }}-->
<!--              </router-link>-->
<!--              <router-link tag="a" to="/schools-list" class="claims"-->
<!--                           :class="{'active': $route.path === '/schools-list'}">{{ $t('sidebar.schools-list') }}-->
<!--              </router-link>-->

              <router-link
                  v-if="user.second_stage_type === 'ss_winner' && user.school_enroll_available"
                  tag="a"
                  to="/schools-list"
                  class="claims"
                  :class="{'active': $route.path === '/schools-list'}"
              >{{ $t('sidebar.schools-list') }}
              </router-link>

              <router-link tag="a" to="/notification" class="notifications"
                           :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}
              </router-link>
              <router-link tag="a" to="/settings" class="settings" :class="{'active': $route.path === '/settings'}">
                {{ $t('sidebar.settings') }}
              </router-link>
              <a v-if="logged" @click.prevent="logoutMethod" class="logout">{{ $t('navbar.signout') }}</a>

<!--              <div class="logout-link">-->
<!--                <ul class="navbar-nav">-->
<!--                  <li v-if="logged" class="nav-item">-->
<!--                    <a @click.prevent="logoutMethod" class="nav-link cursor-pointer">{{ $t('navbar.signout') }}</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->

            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue";

export default {
  name: 'Navbar',
  data() {
    return {
      Questions: {
        it: [],
        creations: [],
        sport: [],
        summerTime: '',
        profession: [],
        country: '',
        sections: '',
        onlineSections: '',
        friendlyTeacher: '',
        friendlyClass: '',
        schoolHelper: '',
        schoolProblems: [],
        schoolDistance: '',
        haveComputer: '',
        internetActivity: [],
        deviceType: '',
        ethernet: '',
        status: '',
        level: '',
        win: '',
        textWin: '',
        textIt: '',
        textCreations: '',
        textSport: '',
        textProfession: ''
      },
      informedCheckbox: false,
      avatar: '',
      user: {},
      showPoleButton: false,
      collapsed: true,
      hasNewNotification: false,
      logged: false,
    }
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.logged = true;
          if (res.data.data.session_id != localStorage.getItem('session_id')) {
            this.logout();
            localStorage.setItem('session_id', '');
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
            window.location = '/';
          }
          this.user = res.body.data;
          this.avatar = `${window.API_ROOT}/api/user/avatar?file=${this.user.avatar}`;
          sessionStorage.setItem('avatar-url', `${window.API_ROOT}/api/user/avatar?file=${this.user.avatar}`);
          const {notifications} = res.body.data.notifications
          this.hasNewNotification = this.checkNewNotification(notifications)
          this.showPoleButton = res.body.quiz_info == true && res.body.data.polled == 0
          this.showPoleButton = false
          localStorage.setItem('user', JSON.stringify(res.body.data));
          this.Questions.status = 'участник'
          if (res.body.data.first_stage_winner) {
            this.Questions.status = 'финалист'
          }
          if (res.body.data.second_stage_winner) {
            this.Questions.status = 'победитель'
          }

          if ($('body').width() <= 992) {
            if (this.showPoleButton == true) {
              this.$bvModal.show('poledModal-mob')
            }
          }

        })

  },
  watch: {
    'Questions.textWin'() {
      this.Questions.win = '__other_option__'
    },
    informedCheckbox() {
      this.$bvModal.hide('poledModal-mob')
      if (this.$i18n.locale == 'ru') {
        this.$bvModal.show('poledModal-2-ru-mob')
      } else if (this.$i18n.locale == 'kz') {
        this.$bvModal.show('poledModal-2-kz-mob')
      }

    }
  },
  methods: {
    sendPoleModal() {
      this.$http.post(`${window.API_ROOT}/api/user/polled`, {
        polled: 1
      })

    },
    openModal() {
      if (this.informedCheckbox == false) {
        this.$bvModal.show('poledModal-mob')
      } else {
        if (this.$i18n.locale == 'ru') {
          this.$bvModal.show('poledModal-2-ru-mob')
        } else if (this.$i18n.locale == 'kz') {
          this.$bvModal.show('poledModal-2-kz-mob')
        }
      }
    },
    postData(url) {
      let internetActivity = '';
      let schoolProblems = '';
      let profession = '';
      let sport = '';
      let creations = '';
      let it = '';
      let QuestionTextWin = '';
      let request;
      if (this.$i18n.locale == 'ru') {
        if (this.Questions.win == '__other_option__') {
          QuestionTextWin = `&entry.1479163013.other_option_response=${encodeURI(this.Questions.textWin)}`
        }

        for (let i = 0; i < this.Questions.it.length; i++) {
          if (this.Questions.it[i] == '__other_option__') {
            if (this.Questions.textIt) {
              it += `&entry.1331595465.other_option_response=${encodeURI(this.Questions.textIt)}&entry.1331595465=${encodeURI(this.Questions.it[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            it += `&entry.1331595465=${encodeURI(this.Questions.it[i])}`
          }
        }

        for (let i = 0; i < this.Questions.internetActivity.length; i++) {
          internetActivity += `&entry.1709066131=${encodeURI(this.Questions.internetActivity[i])}`
        }

        for (let i = 0; i < this.Questions.schoolProblems.length; i++) {
          schoolProblems += `&entry.2026397148=${encodeURI(this.Questions.schoolProblems[i])}`
        }

        for (let i = 0; i < this.Questions.profession.length; i++) {
          if (this.Questions.profession[i] == '__other_option__') {
            if (this.Questions.textProfession) {
              profession += `&entry.1693994627.other_option_response=${encodeURI(this.Questions.textProfession)}&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1693994627=${encodeURI(this.Questions.profession[i])}`
          }
        }
        for (let i = 0; i < this.Questions.sport.length; i++) {
          if (this.Questions.sport[i] == '__other_option__') {
            if (this.Questions.textSport) {
              sport += `&entry.117665550.other_option_response=${encodeURI(this.Questions.textSport)}&entry.117665550=${encodeURI(this.Questions.sport[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            sport += `&entry.117665550=${encodeURI(this.Questions.sport[i])}`
          }


        }
        for (let i = 0; i < this.Questions.creations.length; i++) {

          if (this.Questions.creations[i] == '__other_option__') {
            if (this.Questions.textCreations) {
              creations += `&entry.723918317.other_option_response=${encodeURI(this.Questions.textCreations)}&entry.723918317=${encodeURI(this.Questions.creations[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            creations += `&entry.723918317=${encodeURI(this.Questions.creations[i])}`
          }
        }
        request = `entry.1837795238=${encodeURI(this.user.iin)}` +
            `&entry.218682015=${encodeURI(this.user.region_name)}` +
            `&entry.681155163=${encodeURI(this.user.locality_name)}` +
            `&entry.1145555405=${encodeURI(this.user.city_name)}` +
            `&entry.1274123360=${encodeURI(this.user.education.region_name)}` +
            `&entry.463274075=${encodeURI(this.user.education.location_name)}` +
            `&entry.1043618168=${encodeURI(this.user.education.city_name)}` +
            `&entry.1938511119=${encodeURI('Школа')}` +
            `&entry.1393679014=${encodeURI(this.user.school)}` +
            `&entry.170288098=${encodeURI(this.user.class)}` +
            `&entry.1143191096=${encodeURI(this.user.first_stage)}` +
            `&entry.496385851_year=${encodeURI(Number(this.user.birth_date.substr(0, 4)))}` +
            `&entry.496385851_month=${encodeURI(Number(this.user.birth_date.substr(5, 2)))}` +
            `&entry.496385851_day=${encodeURI(Number(this.user.birth_date.substr(8, 2)))}` +
            `&entry.1331363301=${encodeURI(this.user.sex == 1 ? 'Муж' : 'Жен')}` +
            `&entry.294793165=${encodeURI(this.user.education.instruction_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.46179682=${encodeURI(this.user.education.test_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.2067872883=${encodeURI(this.Questions.status)}` +
            `&entry.1461016368=${encodeURI(this.Questions.level)}` +
            QuestionTextWin +
            `&entry.1479163013=${encodeURI(this.Questions.win)}` +
            `&entry.1550302930=${encodeURI(this.Questions.ethernet)}` +
            `&entry.214357571=${encodeURI(this.Questions.deviceType)}` +
            internetActivity +
            `&entry.1733881976=${encodeURI(this.Questions.haveComputer)}` +
            `&entry.1779434182=${encodeURI(this.Questions.schoolDistance)}` +
            schoolProblems +
            `&entry.98092028=${encodeURI(this.Questions.schoolHelper)}` +
            `&entry.1231476756=${encodeURI(this.Questions.friendlyClass)}` +
            `&entry.1178640644=${encodeURI(this.Questions.friendlyTeacher)}` +
            `&entry.1602905380=${encodeURI(this.Questions.sections)}` +
            `&entry.1029012000=${encodeURI(this.Questions.onlineSections)}` +
            it +
            creations +
            sport +
            profession +
            `&entry.2109720141=${encodeURI(this.Questions.country)}` +
            `&entry.1610799912=${encodeURI(this.Questions.summerTime)}` +
            `&entry.1331363301_sentinel=&entry.294793165_sentinel=&entry.46179682_sentinel=&entry.2067872883_sentinel=&entry.1461016368_sentinel=&entry.1479163013_sentinel=&entry.1550302930_sentinel=&entry.214357571_sentinel=&entry.1709066131_sentinel=&entry.1733881976_sentinel=&entry.1779434182_sentinel=&entry.2026397148_sentinel=&entry.98092028_sentinel=&entry.1231476756_sentinel=&entry.1178640644_sentinel=&entry.1602905380_sentinel=&entry.1029012000_sentinel=&entry.1331595465_sentinel=&entry.723918317_sentinel=&entry.117665550_sentinel=&entry.1610799912_sentinel=&entry.1693994627_sentinel=&entry.2109720141_sentinel=&fvv=1&partialResponse=%5Bnull%2Cnull%2C%226199367058393720432%22%5D&pageHistory=0&fbzx=6199367058393720432`;

      } else if (this.$i18n.locale == 'kz') {
        if (this.Questions.win == '__other_option__') {
          QuestionTextWin = `&entry.1298338927.other_option_response=${encodeURI(this.Questions.textWin)}`
        }

        for (let i = 0; i < this.Questions.it.length; i++) {
          if (this.Questions.it[i] == '__other_option__') {
            if (this.Questions.textIt) {
              it += `&entry.1572485380.other_option_response=${encodeURI(this.Questions.textIt)}&entry.1572485380=${encodeURI(this.Questions.it[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            it += `&entry.1572485380=${encodeURI(this.Questions.it[i])}`
          }
        }

        for (let i = 0; i < this.Questions.internetActivity.length; i++) {
          internetActivity += `&entry.1831503231=${encodeURI(this.Questions.internetActivity[i])}`
        }

        for (let i = 0; i < this.Questions.schoolProblems.length; i++) {
          schoolProblems += `&entry.1934317436=${encodeURI(this.Questions.schoolProblems[i])}`
        }

        for (let i = 0; i < this.Questions.profession.length; i++) {
          if (this.Questions.profession[i] == '__other_option__') {
            if (this.Questions.textProfession) {
              profession += `&entry.1751799059.other_option_response=${encodeURI(this.Questions.textProfession)}&entry.1751799059=${encodeURI(this.Questions.profession[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            profession += `&entry.1751799059=${encodeURI(this.Questions.profession[i])}`
          }
        }

        for (let i = 0; i < this.Questions.sport.length; i++) {
          if (this.Questions.sport[i] == '__other_option__') {
            if (this.Questions.textSport) {
              sport += `&entry.436935992.other_option_response=${encodeURI(this.Questions.textSport)}&entry.436935992=${encodeURI(this.Questions.sport[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            sport += `&entry.436935992=${encodeURI(this.Questions.sport[i])}`
          }


        }
        for (let i = 0; i < this.Questions.creations.length; i++) {

          if (this.Questions.creations[i] == '__other_option__') {
            if (this.Questions.textCreations) {
              creations += `&entry.1098426865.other_option_response=${encodeURI(this.Questions.textCreations)}&entry.1098426865=${encodeURI(this.Questions.creations[i])}`
            } else {
              Vue.toastr({
                message: this.$t('pole-modal.warning'),
                description: this.$t('pole-modal.required'),
                type: 'error'
              })
              return
            }
          } else {
            creations += `&entry.1098426865=${encodeURI(this.Questions.creations[i])}`
          }
        }
        request = `entry.700033346=${encodeURI(this.user.iin)}` +
            `&entry.693013719=${encodeURI(this.user.region_name)}` +
            `&entry.390634861=${encodeURI(this.user.locality_name)}` +
            `&entry.82949384=${encodeURI(this.user.city_name)}` +
            `&entry.348920338=${encodeURI(this.user.education.region_name)}` +
            `&entry.1538933299=${encodeURI(this.user.education.location_name)}` +
            `&entry.1770747759=${encodeURI(this.user.education.city_name)}` +
            `&entry.170788498=${encodeURI('Мектеп')}` +
            `&entry.250945860=${encodeURI(this.user.school)}` +
            `&entry.718510597=${encodeURI(this.user.class)}` +
            `&entry.389593988=${encodeURI(this.user.first_stage)}` +
            `&entry.1372016661_year=${encodeURI(Number(this.user.birth_date.substr(0, 4)))}` +
            `&entry.1372016661_month=${encodeURI(Number(this.user.birth_date.substr(5, 2)))}` +
            `&entry.1372016661_day=${encodeURI(Number(this.user.birth_date.substr(8, 2)))}` +
            `&entry.570574830=${encodeURI(this.user.sex == 1 ? 'Муж' : 'Жен')}` +
            `&entry.1376425566=${encodeURI(this.user.education.instruction_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.1489292218=${encodeURI(this.user.education.test_language == 'ru' ? 'рус' : 'каз')}` +
            `&entry.589168218=${encodeURI(this.Questions.status)}` +
            `&entry.122697157=${encodeURI(this.Questions.level)}` +
            QuestionTextWin +
            `&entry.1298338927=${encodeURI(this.Questions.win)}` +
            `&entry.566726131=${encodeURI(this.Questions.ethernet)}` +
            `&entry.316171337=${encodeURI(this.Questions.deviceType)}` +
            internetActivity +
            `&entry.1685561511=${encodeURI(this.Questions.haveComputer)}` +
            `&entry.78617162=${encodeURI(this.Questions.schoolDistance)}` +
            schoolProblems +
            `&entry.1061289283=${encodeURI(this.Questions.schoolHelper)}` +
            `&entry.896011520=${encodeURI(this.Questions.friendlyClass)}` +
            `&entry.1954730864=${encodeURI(this.Questions.friendlyTeacher)}` +
            `&entry.1019001030=${encodeURI(this.Questions.sections)}` +
            `&entry.1491423878=${encodeURI(this.Questions.onlineSections)}` +
            it +
            creations +
            sport +
            profession +
            `&entry.165211079=${encodeURI(this.Questions.country)}` +
            `&entry.1782540906=${encodeURI(this.Questions.summerTime)}`
      }


      fetch(url, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },

        body: request


      })
      Vue.toastr({
        message: this.$t('pole-modal.success'),
        description: this.$t('pole-modal.success-survey'),
        type: 'success'
      })
      this.sendPoleModal()
      if (this.$i18n.locale == 'ru') {
        this.$bvModal.hide('poledModal-2-ru-mob')
      } else if (this.$i18n.locale == 'kz') {
        this.$bvModal.hide('poledModal-2-kz-mob')
      }
      this.showPoleButton = false


    },
    sendForm() {
      let url;
      let form = JSON.stringify(this.Questions)
      form = JSON.parse(form)
      delete form.textWin
      delete form.textIt
      delete form.textCreations
      delete form.textSport
      delete form.textProfession

      function checkElement(el) {
        return el != '';
      }

      if (Object.values(form).every(checkElement) && this.Questions.it.length <= 3 && this.Questions.creations.length <= 3 && this.Questions.sport.length <= 3 && this.Questions.profession.length <= 3 && this.Questions.schoolProblems.length <= 3 && this.Questions.internetActivity.length <= 3) {
        if (this.$i18n.locale == 'ru') {
          url = 'https://docs.google.com/forms/d/e/1FAIpQLScJLL9eiKjYFnaIfUXaItQLWK294RUQ3fr1LZrNYva4SUsz-A/formResponse'
        } else if (this.$i18n.locale == 'kz') {
          url = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSczPOYJ7GG2qsD_JP9NkgSKr93WbT7xJqjXE8xcLZBNuoWUAA/formResponse'
        }

        this.postData(url);
      } else {
        Vue.toastr({
          message: this.$t('pole-modal.warning'),
          description: this.$t('pole-modal.required'),
          type: 'error'
        })
      }
    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute);
    },
    logoutMethod() {
      localStorage.setItem('session_id', '');
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      window.location = '/';
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    checkNewNotification(notifications) {
      if (Array.isArray(notifications)) {
        for (let i = 0; i < notifications.length; i++) {
          if (notifications[i].is_viewed === 0) {
            return true
          }
        }
        return false
      }
    },
    ...mapActions({
      'logout': 'logout'
    })
  }
}
</script>
<style lang="css">
.el-checkbox {
  text-align: left !important;
  display: flex !important;
  margin-right: 0;
}

@media (max-width: 500px) {
  .modal-dialog {
    margin: 0;
  }

  .close {
    right: 0rem !important;
    top: 0rem !important;
  }

  .modal-body {
    padding: 0.5rem !important;
  }

  .el-checkbox {
    text-align: left !important;
    display: flex !important;
    margin-right: 0;
  }

  .confirm-pole-modal {
    margin-top: 3rem;
    margin-bottom: 6rem;
    font-size: 16px !important;
  }
}

.confirm-pole-modal {
  margin-top: 1rem;
  text-align: center !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2C2C2C !important;
}

.confirm-pole-modal span {
  text-align: center !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2C2C2C !important;
}

.pole-modal-content {
  display: flex !important;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}


</style>
<style lang="css" scoped>

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.modal #dialog div {
  position: relative;
  z-index: 1;
  width: 100%;
}

.el-radio, .el-radio__input {
  white-space: unset;
}

.el-checkbox, .el-checkbox__input {
  text-align: initial;

}

.title-polemodal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #05458C;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pole-modal-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  color: #05458C;

}

.el-radio {
  display: flex;
}

.el-radio-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.el-checkbox-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.error-pole-modal {
  width: 100% !important;
  color: red;
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}
</style>
<style>
.el-dropdown-link {
  text-transform: uppercase;
}

.profile-navbar-mobile {
  display: none;
}

.btn-back-menu {
  border: none;
  background-color: transparent;
}


@media screen and (max-width: 992px) {
  .profile-navbar-mobile {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .menu-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    width: 45px;
    height: 45px;
    background: white;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 66px;
  }

  .nav-logo {
    width: 70px;
  }

  .profile-navbar-site {
    display: none;
  }

  .sideBarMobile {
    display: block;
    border-radius: 12px 0px 0px 12px;
  }

  .w3-container {
    position: absolute;
    right: -38px;
    top: 48px;
    margin-bottom: 10px;
  }

  .modal-menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .content .profile-navbar .nav-link {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    font-feature-settings: 'tnum' on, 'lnum' on;

    color: #707C97;
    padding: 0.5rem 2rem;
  }

  .closeIcon {
    position: relative;
    right: 40px;
    top: 15px;
  }

}

@media screen and (max-width: 370px) {
  .menu-block {
    margin-left: 4px;
  }
}
</style>
<style lang="css" scoped>
.el-radio, .el-radio__input {
  white-space: unset;
}

.el-checkbox, .el-checkbox__input {
  white-space: unset;
}

.title-polemodal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #05458C;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pole-modal-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  color: #05458C;

}

.el-radio {
  display: flex;
}

.el-radio-group {
  width: fit-content;
  display: flex;
  flex-direction: column;
}
</style>

